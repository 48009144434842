import React from "react";
import Card from "../Card/Card";
import {
  StyledWrapper,
  StyledHeading,
  StyledCardsConatiner,
} from "./CardContainer.styles";
import { data } from "./CardContainer.data";

const CardContainer = () => {
  return (
    <StyledWrapper id="our-services">
      <StyledHeading>{data.title}</StyledHeading>
      <StyledCardsConatiner>
        <Card />
      </StyledCardsConatiner>
    </StyledWrapper>
  );
};

export default CardContainer;
