import styled from "styled-components";

export const StyledWrapper = styled.article`
  max-width: 2560px;
  margin-bottom: 100px;
`;

export const StyledHeading = styled.h2`
  color: #2f4858;
  text-align: center;
  padding: 10px 0;
  font-size: 30px;
  border-bottom: 2px solid #2f4858;
  margin-bottom: 20px;
  max-width: 90%;

  @media screen and (min-width: 550px) {
    font-size: 60px;
  }

  @media screen and (min-width: 1024px) {
    padding: 30px 0;
    margin-bottom: 70px;
  }
`;

export const StyledAboutUs = styled.article`
  padding: 0 60px;
  max-width: 2560px;

  @media screen and (min-width: 768px) {
    padding: 0 100px;
  }

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    padding: 0 80px;
  }

  @media screen and (min-width: 1440px) {
    padding: 0 150px;
  }

  @media screen and (min-width: 1920px) {
    padding: 0 200px;
  }
`;

export const StyledDescription = styled.div`
  color: #2f4858;
  text-align: left;
  font-size: 16px;
  align-self: start;
  height: 100%;
  margin-bottom: 20px;

  h3 {
    margin-bottom: 12px;
  }

  img {
    max-width: 40px;
    margin-bottom: 6px;
  }

  @media screen and (min-width: 550px) {
  }

  @media screen and (min-width: 768px) {
    font-size: 24px;

    img {
      max-width: 50px;
    }
  }

  @media screen and (min-width: 1440px) {
    font-size: 35px;
  }

  @media screen and (min-width: 1920px) {
    /* display: none; */
  }
`;

export const StyledImage = styled.div``;
