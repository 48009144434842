import bannerTdrill from "../../images/pipes-background.png";
import bannerTeuvan from "../../images/teuvankitchen.png";

export const data = [
  {
    logo: "t-drill-red",
    background: `url(${bannerTdrill})`,
    link: "https://t-drill.com/pl/",
    img1: "t-drill-machine1",
    img2: "t-drill-machine2",
    altText1: "t-drill logo",
    altText2: "t-drill machine",
    altText3: "t-drill machine",
    description: `T-DRILL jest producentem zaawansowanych technologicznie rozwiązań w zakresie obróbki rur i przewodów rurowych. Nasze standardowe produkty składają się z szerokiej gamy obrabiarek, które znajdują zastosowanie w wielu różnych branżach przemysłu, od motoryzacyjnego po stoczniowy. Oprócz szerokiej gamy produktów kładziemy duży nacisk na rozwój produktów i wsparcie posprzedażowe. Rozwój ten jest poparty efektywnym systemem produkcji i wieloletnim doświadczeniem.
    Krótko mówiąc, rodzina profesjonalnych rozwiązań T-DRILL pomaga swoim klientom zwiększyć wydajność, oferując im długotrwałe korzyści i przewagę konkurencyjną.
    `,
  },
  {
    logo: "teuvan",
    background: `url(${bannerTeuvan})`,
    link: "http://teuvan.pl/",
    img1: "teuvan-kitchen",
    img2: "grill-teuvan",
    altText1: "teuvan logo",
    altText2: "teuvan mobile kitchen",
    altText3: "teuvan grill",
    description: `Teuvan produkuje Mobilne Produkty Kuchenne najwyższej jakości, które są niezawodne w użyciu w najbardziej ekstremalnych warunkach. Nasz asortyment jest rozwijany dzięki innowacyjności i doświadczeniu od 1925 roku. Nasze produkty zostały zaprojektowane z myślą o łatwości użytkowania, mobilności i niezawodności.
    Oprócz nowoczesnych kuchni mobilnych do szybkiego wykorzystania przez siły zbrojne, produkujemy również produkty do użytku w sektorze cywilnym, w tym piece do saun, mobilny sprzęt gastronomiczny oraz profesjonalne kuchenki kuchenne zaprojektowane zgodnie z wymaganiami klientów.
    `,
  },
];
