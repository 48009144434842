import React from "react";

import {
  StyledWrapper,
  StyledHeading,
  StyledAboutUs,
  StyledDescription,
} from "./AboutUs.styles";
import { data } from "./AboutUs.data";

const AboutUs = () => {
  return (
    <>
      <StyledWrapper id="about-us">
        <StyledHeading>{data.title}</StyledHeading>
        <StyledAboutUs>
          {data.description.map((item, i) => (
            <StyledDescription key={i}>
              <img
                src={require("../../images/" + item.source + ".png")}
                alt={item.altText}
              />
              <h3>{item.heading}</h3>
              <p>{item.line}</p>
            </StyledDescription>
          ))}
        </StyledAboutUs>
      </StyledWrapper>
    </>
  );
};

export default AboutUs;
