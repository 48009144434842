import React from "react";
import { useState } from "react";
import { data } from "./ContactCard.data";
import * as FaIcons from "react-icons/fa";

import {
  StyledWrapper,
  StyledBoxCenter,
  StyledUserDetails,
  StyledText,
  StyledButton,
  StyledHiddenContent,
  StyledTitle,
  StyledContact,
} from "./ContactCard.styles";

const ContactCard = ({ person }) => {
  const [isVisible, setVisible] = useState(false);
  const splitNumber = (phoneNumber) => {
    phoneNumber.replaceAll(" ", "-");
  };

  return (
    <StyledWrapper>
      <StyledBoxCenter>
        <img
          src={require("../../images/people/" + person.photo + ".png")}
          alt={person.name + "photo"}
        />
        <StyledUserDetails>
          <StyledText>{person.name}</StyledText>
          <StyledText className="sub">{person.occupation}</StyledText>
          <StyledText className="sub">{person.company}</StyledText>
        </StyledUserDetails>
        <StyledButton onClick={() => setVisible(!isVisible)}>
          Dane kontaktowe
        </StyledButton>
        <StyledHiddenContent className={isVisible ? "active" : "off"}>
          <StyledTitle>{person.contact}</StyledTitle>
          <StyledContact>
            <FaIcons.FaPhone className="icon" />
            <a href={`tel:${splitNumber(person.phone)}`}>{person.phone}</a>
          </StyledContact>
          <StyledContact>
            <FaIcons.FaMailBulk className="icon" />
            <a href={`mailto:${person.mailTarga}`}>{person.mailTarga}</a>
          </StyledContact>
          <StyledContact>
            <FaIcons.FaMailBulk className="icon" />
            <a href={`mailto:${person.mailTeuvan}`}>{person.mailTeuvan}</a>
          </StyledContact>
          <StyledButton onClick={() => setVisible(!isVisible)}>
            Zamknij
          </StyledButton>
        </StyledHiddenContent>
      </StyledBoxCenter>
    </StyledWrapper>
  );
};

export default ContactCard;
