import styled from "styled-components";

export const Nav = styled.nav`
  position: fixed;
  width: 100%;
  background: #2f4858;
  height: 80px;
  display: flex;
  justify-content: center;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.6);

  @media screen and (min-width: 550px) {
    justify-content: space-between;
  }
`;

export const NavLink = styled.p`
  color: #fff;
  position: relative;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &::after {
    display: block;
    content: "";
    border-bottom: solid 3px #24dc04;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

export const Logo = styled.div`
  cursor: pointer;

  img {
    max-width: 150px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 550px) {
    display: none;
  }
`;

export const NavBtnLink = styled.button`
  position: relative;
  color: #004300;
  font-weight: bold;
  border-radius: 40px;
  background: linear-gradient(45deg, #24dc04, #fff);
  padding: 10px 22px;
  border: none;
  cursor: pointer;
  margin-left: 24px;
  isolation: isolate;

  &::after {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 40px;
    background: linear-gradient(45deg, #24dc04, #fff);
    color: white;
    z-index: -1;
    filter: blur(10px);
    transition: 0.5s ease;
    opacity: 0;
  }

  &:hover::after {
    /* filter: blur(15px); */
    opacity: 1;
  }
`;
