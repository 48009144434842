import styled from "styled-components";

export const StyledFooter = styled.footer`
  background-color: #24dc04;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0;
  }
`;

export const StyledContainer = styled.div`
  border-bottom: 1px solid #2f4858;
  width: 100%;
  text-align: center;
  padding-bottom: 10px;

  @media screen and (min-width: 768px) {
    border-bottom: none;
    width: auto;
    text-align: justify;
  }
`;

export const StyledAdress = styled.div`
  height: 100%;
  padding-right: 10px;
`;

export const StyledAdressDetail = styled.p`
  color: #2f4858;
`;

export const StyledContact = styled.div`
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px 0;
  }
`;

export const LogoFooter = styled.div`
  transform: translateX(-10px);
  cursor: pointer;

  img {
    max-width: 150px;
  }
`;

export const StyledLinks = styled.ul`
  text-align: center;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    text-align: justify;
  }
`;

export const StyledLink = styled.li`
  list-style-type: none;
  color: #2f4858;
  font-size: 16px;
  padding: 10px 10px 0;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
`;
