export const data = {
  title: "O nas",
  description: [
    {
      heading: "Doświadczenie",
      line: `Targa jest na polskim rynku już ponad 30 lat. Celem naszej firmy
      jest satysfakcja klienta, a nasza oferta jest zawsze dostosowana do
      indywidualnych potrzeb. Siedziba firmy mieści się w Żyrardowie
      jednak obsługujemy klientów na terenie całego kraju.`,
      source: "calendar",
      altText: "calendar",
    },
    {
      heading: "Zespół",
      line: `Nasz zespół
      handlowców posiada wieloletnie doświadczenie z zakresu obróbki
      plastycznej rur, a nasze maszyny codziennie pracują w branżach takich
      jak: Przemysł motoryzacyjny, stoczniowy, HVAC, kanalizacja i
      uzdatnianie wody, przemysł spożywczy i mleczarski, farmaceutyczny
      oraz chemiczny itp.`,
      source: "team",
      altText: "team",
    },
    {
      heading: "Wyłączność",
      line: `Jesteśmy wyłącznym przedstawicielem firmy
      T-Drill oraz firmy Teuvan na terenie Polski.`,
      source: "suitcase",
      altText: "suitcase",
    },
  ],
};
