import React from "react";
import { Link } from "react-scroll";
import { Nav, NavLink, Logo, NavMenu, NavBtnLink } from "./Navigation.styles";

const Navigation = () => {
  return (
    <>
      <Nav>
        <Logo>
          <Link to="hero" spy={true} smooth={true} duration={100}>
            <img
              src={require("../../images/logos/targa-white.png")}
              alt="targa logo"
            />
          </Link>
        </Logo>
        <NavMenu>
          <NavLink activeStyle>
            <Link to="our-services" spy={true} smooth={true} duration={100}>
              Nasze Usługi
            </Link>
          </NavLink>
          <NavLink activeStyle>
            <Link to="about-us" spy={true} smooth={true} duration={100}>
              O nas
            </Link>
          </NavLink>
          <NavBtnLink>
            <Link to="contact-us" spy={true} smooth={true} duration={100}>
              Kontakt
            </Link>
          </NavBtnLink>
        </NavMenu>
      </Nav>
    </>
  );
};

export default Navigation;
