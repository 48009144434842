import React from "react";
import "./App.css";
import Navigation from "./components/Navigation/Navigation";
import MobileNavigation from "./components/MobileNavigation/MobileNavigation";
import Banner from "./components/Banner/Banner";
import AboutUs from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import { breakpoints as breakpointsValues } from "./hooks/breakpoints";
import CardContainer from "./components/CardContainer/CardContainer";
import ContactCardContainer from "./components/ContactCardContainer/ContactCardContainer";

function App() {
  const getWindowWidth = () => window.innerWidth;
  const width = getWindowWidth();

  return (
    <Router>
      {width >= breakpointsValues.sm ? <Navigation /> : <MobileNavigation />}
      <Banner />
      <CardContainer />
      <AboutUs />
      <ContactCardContainer />
      <Footer />
    </Router>
  );
}

export default App;
