import React from "react";
import { useState } from "react";
import Hamburger from "../Hamburger/Hamburger";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

import {
  Nav,
  NavLink,
  Logo,
  Bars,
  NavMenu,
  NavBtnLink,
} from "./MobileNavigation.styles";

const MobileNavigation = () => {
  const [open, setOpen] = useState(false);
  const animateFrom = { opacity: 0, y: -40 };
  const animateTo = { opacity: 1, y: 0 };
  const changeState = () => {
    setOpen(open);
  };

  return (
    <>
      <Nav>
        <Logo>
          <Link to="hero" spy={true} smooth={true} duration={100}>
            <img
              src={require("../../images/logos/targa-white.png")}
              alt="targa logo"
            />
          </Link>
        </Logo>
        <Bars onClick={() => setOpen(!open)}>
          <Hamburger open={open} />
        </Bars>
        {open && (
          <NavMenu>
            <motion.span
              onClick={() => setOpen(!open)}
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.1 }}
            >
              <NavLink activeStyle>
                <Link
                  to="our-services"
                  spy={true}
                  smooth={true}
                  duration={100}
                  onClick={() => setOpen(!open)}
                >
                  Nasze Usługi
                </Link>
              </NavLink>
            </motion.span>
            <motion.span
              onClick={() => setOpen(!open)}
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.2 }}
            >
              <NavLink activeStyle>
                <Link
                  to="about-us"
                  spy={true}
                  smooth={true}
                  duration={100}
                  onClick={() => setOpen(!open)}
                >
                  O Nas
                </Link>
              </NavLink>
            </motion.span>
            <motion.span
              onClick={() => setOpen(!open)}
              initial={animateFrom}
              animate={animateTo}
              transition={{ delay: 0.3 }}
            >
              <Link
                to="contact-us"
                spy={true}
                smooth={true}
                duration={100}
                onClick={() => setOpen(!open)}
              >
                <NavBtnLink>Kontakt</NavBtnLink>
              </Link>
            </motion.span>
          </NavMenu>
        )}
      </Nav>
    </>
  );
};

export default MobileNavigation;
