import React from "react";
import {
  StyledFooter,
  LogoFooter,
  StyledLinks,
  StyledLink,
  StyledAdress,
  StyledAdressDetail,
  StyledContainer,
  StyledContact,
} from "./Footer.styles";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <StyledFooter>
      <StyledContainer>
        <LogoFooter>
          <Link to="hero" spy={true} smooth={true} duration={100}>
            <img
              src={require("../../images/logos/targa-navy.png")}
              alt="targa logo"
            />
          </Link>
        </LogoFooter>
        <StyledContact>
          <StyledAdress>
            <StyledAdressDetail>Targa Łukasz Kielczyk</StyledAdressDetail>
            <StyledAdressDetail>ul. Poznańska 10</StyledAdressDetail>
            <StyledAdressDetail>96-300 Żyrardów</StyledAdressDetail>
          </StyledAdress>
          <StyledAdress>
            <StyledAdressDetail telt>
              <a href="tel:+48-500-512-276">tel.: +48 500 512 276</a>
            </StyledAdressDetail>
            <StyledAdressDetail>
              <a href="mailto:targa@targa.pl">targa@targa.pl</a>
            </StyledAdressDetail>
          </StyledAdress>
        </StyledContact>
      </StyledContainer>
      <StyledLinks>
        <StyledLink>
          <Link to="our-services" spy={true} smooth={true} duration={100}>
            Nasze Usługi
          </Link>
        </StyledLink>
        <StyledLink>
          <Link to="about-us" spy={true} smooth={true} duration={100}>
            O nas
          </Link>
        </StyledLink>
        <StyledLink>
          <Link to="contact-us" spy={true} smooth={true} duration={100}>
            Kontakt
          </Link>
        </StyledLink>
      </StyledLinks>
    </StyledFooter>
  );
};

export default Footer;
