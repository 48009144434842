import styled from "styled-components";

export const StyledContent = styled.div`
  max-width: 95%;
  background: #2f4858;
  border-radius: 50px;
  /* padding: 20px; */
  text-align: center;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
    max-width: 600px;
  }

  @media screen and (min-width: 1024px) {
    margin-bottom: 40px;

    max-width: 700px;
  }

  @media screen and (min-width: 1440px) {
    height: 100%;
  }

  @media screen and (min-width: 1920px) {
    max-width: 900px;
  }
`;

export const StyledLogo = styled.div`
  background-image: ${(props) => props.bg};
  text-align: center;
  cursor: pointer;
  width: 100%;
  padding: 0;
  border-radius: 50px 50px 0 0;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  height: 90px;
  display: flex;
  align-items: center;

  img {
    margin: 0 auto;
    max-width: 45%;
    max-height: 45%;
  }

  @media screen and (min-width: 768px) {
    height: 120px;

    img {
      max-width: 200px;
      height: 80px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 150px;

    img {
      max-width: 250px;
      height: 100px;
    }
  }

  @media screen and (min-width: 1440px) {
    height: 200px;

    img {
      margin: 0 auto;
      max-width: 300px;
    }
  }

  @media screen and (min-width: 1440px) {
    height: 250px;

    img {
      margin: 0 auto;
      max-width: 400px;
      height: 150px;
    }
  }
`;

export const StyledImages = styled.div`
  margin: 10px 0px 0;

  img {
    max-width: 50%;
    max-height: 50%;
  }

  @media screen and (min-width: 420px) {
    margin: 20px 10px 0;

    img {
      max-width: 180px;
      max-height: 250px;
    }
  }

  @media screen and (min-width: 768px) {
    margin: 20px 10px 0;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  @media screen and (min-width: 1024px) {
    margin: 20px 10px 0;

    img {
      max-width: 300px;
      max-height: 250px;
    }
  }

  @media screen and (min-width: 1440px) {
    margin: 20px 10px 0;

    img {
      max-width: 300px;
      max-height: 200px;
    }
  }

  @media screen and (min-width: 1920px) {
    margin: 60px 40px 0;

    img {
      max-width: 400px;
      max-height: 300px;
    }
  }
`;

export const StyledDescription = styled.p`
  color: #fff;
  font-size: 16px;
  padding: 15px 8px 25px;

  @media screen and (min-width: 520px) {
    padding: 0 25px 40px;
  }

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (min-width: 1440px) {
    font-size: 22px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 24px;
  }
`;
