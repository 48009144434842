import styled from "styled-components";

export const StyledWrapper = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 20px 0;
`;

export const StyledBoxCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2f4858;
  width: 300px;
  height: 350px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.288);
  border-radius: 23px;
  flex-direction: column;
  color: #fff;
  position: relative;
  overflow: hidden;
  flex-wrap: wrap;
  flex-shrink: 50;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  @media screen and (min-width: 550px) {
    width: 500px;
    height: 500px;

    img {
      width: 200px;
      height: 200px;
    }
  }
`;

export const StyledUserDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledText = styled.div`
  font-size: 1.2rem;
  padding-bottom: 5px;

  &.sub {
    color: rgb(155, 155, 155);
  }

  @media screen and (min-width: 550px) {
    font-size: 1.8rem;
  }
`;

export const StyledButton = styled.button`
  position: relative;
  color: #004300;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 40px;
  background: linear-gradient(45deg, #24dc04, #fff);
  padding: 10px 22px;
  border: none;
  cursor: pointer;
  isolation: isolate;

  &::after {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 40px;
    background: linear-gradient(45deg, #24dc04, #fff);
    color: white;
    z-index: -1;
    filter: blur(10px);
    transition: 0.5s ease;
    opacity: 0;
  }

  &:hover::after {
    /* filter: blur(15px); */
    opacity: 1;
  }

  @media screen and (min-width: 550px) {
    font-size: 1.6rem;
  }
`;

export const StyledHiddenContent = styled.div`
  position: absolute;
  background: #004d73;
  width: 100%;
  height: 100%;
  border-radius: 23px;
  padding: 40px 20px 0 20px;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;

  &.off {
    transform: translate(-80%, -80%) rotate(90deg);
  }

  &.active {
    transform: translate(0) rotate(0);
  }
`;

export const StyledTitle = styled.h4`
  font-size: 1.4rem;
  margin-bottom: 20px;

  @media screen and (min-width: 550px) {
    font-size: 1.8rem;
    margin-bottom: 40px;
  }
`;

export const StyledContact = styled.div`
  & > .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  & > a {
    text-decoration: none;
    color: #fff;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }

  & > a:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 550px) {
    & > .icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }

    & > a {
      font-size: 1.8rem;
      padding-bottom: 8px;
    }
  }
`;
