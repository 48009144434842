import styled from "styled-components";
import banner from "../../images/banner.png";
import bannerMobile from "../../images/banner-mobile.png";

export const StyledBanner = styled.div`
  padding-top: 80px;
  background-image: url(${bannerMobile});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  height: 500px;
  max-width: 2560px;

  @media screen and (min-width: 768px) {
    height: 600px;
  }

  @media screen and (min-width: 1024px) {
    background-position: 75%;
  }

  @media screen and (min-width: 1440px) {
    background-image: url(${banner});
    height: 750px;
  }
`;

export const StyledHeader = styled.div`
  max-width: 250px;
  color: #2f4858;
  position: relative;
  top: 30%;

  @media screen and (min-width: 520px) {
    max-width: 300px;
  }

  @media screen and (min-width: 768px) {
    max-width: 400px;
    top: 5%;
    left: -20%;
  }

  @media screen and (min-width: 1024px) {
    max-width: 450px;
    left: -25%;
  }

  @media screen and (min-width: 1440px) {
    max-width: 500px;
    left: -30%;
  }

  @media screen and (min-width: 1920px) {
    left: -33%;
  }

  h1 {
    font-size: 40px;
    font-weight: 100;
    color: #fff;

    @media screen and (min-width: 520px) {
      font-size: 50px;
    }

    @media screen and (min-width: 768px) {
      font-size: 60px;
    }

    @media screen and (min-width: 1024px) {
      font-size: 70px;
    }

    @media screen and (min-width: 1440px) {
      font-size: 80px;
    }
  }

  img {
    max-width: 250px;

    @media screen and (min-width: 520px) {
      max-width: 300px;
    }

    @media screen and (min-width: 768px) {
      max-width: 400px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 450px;
    }

    @media screen and (min-width: 1440px) {
      max-width: 500px;
    }

    @media screen and (min-width: 1920px) {
      max-width: 550px;
    }

    @media screen and (min-width: 2560px) {
      max-width: 650px;
    }
  }

  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }

    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-20px);
    }

    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }

  &::after {
    position: relative;
    content: "";
    display: block;
    height: 25px;
    width: 25px;
    top: -61px;
    left: 153px;
    border-radius: 50%;
    background: linear-gradient(360deg, #00a68b, #24dc04);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;

    @media screen and (min-width: 520px) {
      height: 31px;
      width: 31px;
      top: -73px;
      left: 184px;
    }

    @media screen and (min-width: 768px) {
      height: 42px;
      width: 42px;
      top: -100px;
      left: 242px;
    }

    @media screen and (min-width: 1024px) {
      height: 48px;
      width: 48px;
      top: -114px;
      left: 270px;
    }

    @media screen and (min-width: 1440px) {
      height: 55px;
      width: 55px;
      top: -129px;
      left: 303px;
    }

    @media screen and (min-width: 1920px) {
      height: 60px;
      width: 60px;
      top: -137px;
      left: 330px;
    }

    @media screen and (min-width: 2560px) {
      height: 70px;
      width: 70px;
      top: -160px;
      left: 395px;
    }
  }
`;
