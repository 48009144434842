export const breakpoints = {
  xxs: 320,
  xs: 420,
  sm: 550,
  md: 768,
  lg: 1024,
  xl: 1440,
  xxl: 1920,
  xxxl: 2560,
};
