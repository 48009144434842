import React from "react";
import {
  StyledContent,
  StyledLogo,
  StyledImages,
  StyledDescription,
} from "../Card/Card.styles";
import { data } from "./Card.data";

const Card = () => {
  return (
    <>
      {data.map((item, i) => (
        <StyledContent key={i}>
          <StyledLogo bg={item.background}>
            <a href={item.link}>
              <img
                src={require("../../images/logos/" + item.logo + ".png")}
                alt={item.altText1}
              />
            </a>
          </StyledLogo>
          <StyledImages>
            <img
              src={require("../../images/" + item.img1 + ".png")}
              alt={item.altText2}
            />
            <img
              src={require("../../images/" + item.img2 + ".png")}
              alt={item.altText3}
            />
          </StyledImages>
          <StyledDescription>{item.description}</StyledDescription>
        </StyledContent>
      ))}
    </>
  );
};
export default Card;
