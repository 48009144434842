import styled from "styled-components";

export const Nav = styled.nav`
  position: fixed;
  width: 100%;
  background: #2f4858;
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
  box-shadow: 0 0px 20px 0px rgba(0, 0, 0, 0.6);
`;

export const Logo = styled.div`
  img {
    max-width: 150px;
  }
`;

export const Bars = styled.div`
  display: block;
  position: absolute;
  top: 30px;
  right: 15px;
  font-size: 1.8rem;
  cursor: pointer;
`;

export const NavMenu = styled.ul`
  z-index: 11;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  right: 0;
  width: 100%;
  background: #2f4858;
`;

export const NavLink = styled.li`
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  list-style-type: none;
  padding: 20px 0;

  &::after {
    display: block;
    content: "";
    border-bottom: solid 3px #24dc04;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

export const NavBtnLink = styled.button`
  position: relative;
  color: #004300;
  font-size: 20px;
  border-radius: 40px;
  background: linear-gradient(45deg, #24dc04, #fff);
  padding: 10px 22px;
  border: none;
  cursor: pointer;
  isolation: isolate;
  margin-bottom: 20px;

  &::after {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 40px;
    background: linear-gradient(45deg, #24dc04, #fff);
    color: white;
    z-index: -1;
    filter: blur(10px);
    transition: 0.5s ease;
    opacity: 0;
  }

  &:hover::after {
    /* filter: blur(15px); */
    opacity: 1;
  }

  @media screen and (min-width: 550px) {
    margin-left: 24px;
  }
`;
