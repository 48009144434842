import React from "react";
import {
  StyledWrapper,
  StyledHeading,
  StyledContactContainer,
} from "./ContactCardContainer.styles";
import { data } from "./ContactCardContainer.data";
import ContactCard from "../ContactCard/ContactCard";
import { userData } from "../ContactCard/ContactCard.data";

const ContactCardContainer = () => {
  return (
    <StyledWrapper id="contact-us">
      <StyledHeading>{data.title}</StyledHeading>
      <StyledContactContainer>
        {userData.map((person) => (
          <ContactCard person={person} />
        ))}
      </StyledContactContainer>
    </StyledWrapper>
  );
};

export default ContactCardContainer;
