export const userData = [
  {
    id: 1,
    photo: "bartosz",
    name: "Bartosz Felczak",
    occupation: "Sales Manager",
    company: "T-Drill & Teuvan",
    contact: "Dane Kontaktowe",
    phone: "+48 500 512 276",
    mailTarga: "targa@targa.pl",
    mailTeuvan: "kontakt@teuvan.pl",
  },
  {
    id: 2,
    photo: "lukasz",
    name: "Łukasz Kielczyk",
    occupation: "CEO",
    company: "T-Drill & Teuvan",
    contact: "Dane Kontaktowe",
    phone: "+48 501 205 966",
    mailTarga: "targa@targa.pl",
    mailTeuvan: "kontakt@teuvan.pl",
  },
  {
    id: 3,
    photo: "krzysztof",
    name: "Krzysztof Sobczyński",
    occupation: "Sales Manager",
    company: "Teuvan",
    contact: "Dane Kontaktowe",
    phone: "+48 513 512 664",
    mailTarga: "targa@targa.pl",
    mailTeuvan: "kontakt@teuvan.pl",
  },
];
