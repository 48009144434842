import React from "react";

import { StyledBanner, StyledHeader } from "./Banner.styles";

const Banner = () => (
  <>
    <StyledBanner
      id="hero"
      imgUrl={process.env.PUBLIC_URL + "../../images/banner.png"}
    >
      <StyledHeader>
        <h1>
          Welcome to
          <img
            src={require("../../images/logos/targa-empty-white.png")}
            alt="targa"
          />
        </h1>
      </StyledHeader>
    </StyledBanner>
  </>
);

export default Banner;
