import styled from "styled-components";

export const StyledWrapper = styled.article`
  max-width: 2560px;
  margin-top: 20px;
`;

export const StyledHeading = styled.h2`
  color: #2f4858;
  text-align: center;
  padding: 10px 0;
  font-size: 30px;
  border-bottom: 2px solid #2f4858;
  margin-bottom: 20px;
  max-width: 90%;

  @media screen and (min-width: 550px) {
    font-size: 60px;
  }

  @media screen and (min-width: 1024px) {
    padding: 30px 0;
    margin-bottom: 70px;
  }
`;

export const StyledCardsConatiner = styled.div`
  @media screen and (min-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 2560px;
  }
`;
