import React from "react";
import { StyledHamburger } from "./Hamburger.styles";

const Hamburger = ({ open }) => (
  <>
    <StyledHamburger className={`${open && "active"}`}>
      <span></span>
      <span></span>
      <span></span>
    </StyledHamburger>
  </>
);

export default Hamburger;
