import styled from "styled-components";

export const StyledHamburger = styled.div`
  & > span {
    display: block;
    width: 28px;
    height: 2px;
    border-radius: 9999px;
    background-color: white;
  }

  & > span:not(:last-child) {
    margin-bottom: 7px;
  }

  &,
  & > span {
    transition: all 0.8s ease-in-out;
  }

  &.active {
    transition-delay: 0.8s;
    transform: rotate(45deg);
  }

  &.active > span:nth-child(2) {
    width: 0;
  }

  &.active > span:nth-child(1),
  &.active > span:nth-child(3) {
    transition: 0.6s;
  }

  &.active > span:nth-child(1) {
    transform: translateY(9px);
  }

  &.active > span:nth-child(3) {
    transform: translateY(-9px) rotate(90deg);
  }
`;
